<template>
  <v-app>
    <div v-if="this.signedIn" class="wrapper">

      <div class="home">
        <div class="lessonHeader">
          <h1 class="ma-0">Hello, {{ this.username }}</h1>
          <h2>Admin Control Panel</h2>
        </div>
      </div>

      <div class="contentContainer">
        <h3 class="mb-2">Edit Content</h3>

        <div id="nav">
          <router-link to="/nouns"><span class="adminLinks">Nouns</span></router-link> |
          <router-link to="/sentencesnouns"><span class="adminLinks">Sentences &mdash; Nouns</span></router-link> |
          <router-link to="/adjectives"><span class="adminLinks">Adjectives</span></router-link> |
          <router-link to="/sentencesadjectives"><span class="adminLinks">Sentences &mdash; Adjectives</span></router-link> |
          <router-link to="/editlessons"><span class="adminLinks">Edit Lessons</span></router-link>
        </div>

        <h3 class="mt-4">Stats</h3>

        <v-card class='pa-2 mb-2' max-width="1000">
          <NounGenderChart style="float:left" :values='this.nounGenderQuantities' :key="componentKey" />
          <h3>Nouns</h3>
          <p>Number of nouns (complete): <b>{{ this.stats.numberOfMasculineNouns + this.stats.numberOfFeminineNouns + this.stats.numberOfNeuterNouns }}</b></p>
          <p>Number of nouns sentences: <b>{{ this.stats.numberOfSentencesNouns }}</b></p>
        </v-card>

        <v-card class='pa-2 mb-2' max-width="1000">
          <NounGenderChart style="float:right;visibility:hidden;" :values='this.nounGenderQuantities' :key="componentKey" />
          <h3>Adjectives</h3>
          <p>Number of adjectives (complete): <b>{{ this.stats.numberOfAdjectives }}</b></p>
          <p>Number of adjectives sentences: <b>{{ this.stats.numberOfSentencesAdjectives }}</b></p>
        </v-card>

        <v-card class='pa-2 mb-2' max-width="1000">
          <NounGenderChart style="float:left;visibility:hidden;" :values='this.nounGenderQuantities' :key="componentKey" />
          <h3>Users</h3>
          <p>Number of users: <b>{{ this.stats.numberOfUsers }}</b></p>
        </v-card>

        <v-card class='pa-2 mb-2' max-width="1000">
          <v-btn @click="calculateNumberOfCombinations()">Calculate Number of Combinations</v-btn>
          {{ this.totalCombinations }}
        </v-card>

      </div>

    </div>
  </v-app>
</template>

<style scoped>
  .wrapper {
    background-color: #f8f9fa;
    min-height: 100%;
  }

  #nav {
    margin: 0;
    padding: 0 0 20px 0;
  }

  .adminLinks {
    color: #fff;
    background: #383838;
    padding: 8px 14px 10px 14px;
    border: 1px solid #383838;
    border-radius: 30px;
  }

  .lessonHeader {
    color: #fff;
    max-width: 1000px;
    font-size: 1.2em;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: calc(50vw - 500px)
  }

  .home {
    height: 100px;
    text-align: left;
    background-color: #383838;
    background-size: 160px;
    background-repeat: no-repeat;
    background-position: calc(50vw + 320px) 0px;
    position: relative;
    align-content: center;
  }

  .contentContainer {
    max-width: 1000px;
    margin: auto;
  }

  .grid-container {
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 5px;
  }
  .grid-item {
    background-color: rgba(255, 255, 255, 1);
    border: 5px solid rgba(0, 0, 0, 1);
    text-align: center;
  }

  .card {
    width: calc(50% - 20px);
    margin: 10px;
    height: 100%;
  }

  .nounsInfo {
    background-color: #ffbe0b;
  }

  .adjectivesInfo {
    background-color: #ada7c9;
  }

  .task2Info {
    background-color: #90be6d;
  }

  .grid-item p {
    padding: 20px 0 0 0;
  }

  .hideOnIndex {
    display: none;
  }
  h1 {
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 1em;
  }

  h3 {
    font-size: 2em;
    margin: 0;
    padding: 0;
  }

  .fullScreen {
    background-color: rgba(0,0,0,0);
    height:calc(100vh);
    background: radial-gradient(circle at calc(50vw + 400px), rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.75) 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .indexBoxContent {
    width: 1000px;
    margin: auto;
    padding: 0 0 60px 0;
    text-align: left;
  }

  .indexBox {
    width: 1000px;
    padding: 20px;
    margin: auto;
    display: flex;
    align-items: center;
  }


  .indexCircle {
    width: 100vw;
    height: 60vw;
    background-color: rgba(0,0,0,0.2);
    margin: auto;
    margin-top: calc(40vh + 64px);
  }


  /* Animation etc */

  .arrowJump {
    transform: translate(0, -50%);
    animation: jump 5s infinite;
  }

  @keyframes jump {
      0% { transform: translate(0, -50%) }

      70% { transform: translate(0, -150%) }

      100% { transform: translate(0, -50%) }
  }
</style>

<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

import NounGenderChart from '@/components/NounGenderChart.vue'

export default {
  title: "Admin",
  components: {
    NounGenderChart
  },
  data() {
    return {
      signedIn: false,
      select: "",
        items: [
          'Programming',
          'Design',
          'Vue',
          'Vuetify',
        ],
      categories: ['test', 'hello'],
      displayName: "",
      stats: [],
      nounGenderQuantities: [],
      componentKey: 0,
      totalCombinations: ""
    }
  },
  methods: {

    async calculateNumberOfCombinations() {

      var nounCombinations = 0;
      var adjectiveCombinations = 0;

      await db.collection("SentencesNouns")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {

            var sentence = doc.data();
            nounCombinations += sentence.words.length

          });
        })
        .catch((error) => {
            console.log("Error getting documents: ", error);
        });

        await db.collection("SentencesAdjectives")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {

              var sentence = doc.data();
              adjectiveCombinations += sentence.words.length

            });
          })
          .catch((error) => {
              console.log("Error getting documents: ", error);
          });

        console.log("Noun Combinations: " + nounCombinations);
        console.log("Adjective Combinations: " + adjectiveCombinations);
        var totalCombinations = nounCombinations + adjectiveCombinations
        console.log("TOTAL: " + totalCombinations);
        this.totalCombinations = "TOTAL: " + totalCombinations

    },

    async checkIfAdmin() {
      var user = await db.collection("Users").doc(this.uid).get();
      user = user.data();

      if (!user.admin) {
        this.$router.push({ name: 'Index'})
      }
      // return user.admin;
    }

  },
  async beforeMount() {


    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.signedIn = true;
        this.username = user.displayName;

        this.checkIfAdmin(user.uid);

        // var admin = this.checkIfAdmin(this.uid)
        // console.log("ADMIN: " + admin);
        // if (!admin) {
        //   this.$router.push({ name: 'Index'})
        // }


      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });












    var sentenceDeclensionCounts = [0, 0, 0, 0, 0, 0]
    var caseAbbreviations = ['nom', 'acc', 'prep', 'gen', 'dat', 'inst']

    await db.collection("SentencesNouns")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          var sentence = doc.data();
          sentenceDeclensionCounts[caseAbbreviations.indexOf(sentence.caseRequired)]++

        });
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });

    var names = "| "
    var counts = "|  "


    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");
    console.log("");

    for (var i = 0; i < sentenceDeclensionCounts.length; i++) {
      if (caseAbbreviations[i].length == 3) {
        names += caseAbbreviations[i] + " | "
        counts += sentenceDeclensionCounts[i] + "  |  "
      } else {
        names += caseAbbreviations[i] + " | "
        counts += sentenceDeclensionCounts[i] + "   |  "
      }
    }

    console.log("+-------------------------------------+");
    console.log("| SENTENCES (NOUNS)                   |");
    console.log("+-------------------------------------+");
    console.log(names);
    console.log(counts);
    console.log("+-------------------------------------+");

    console.log("");
    console.log("");

    names = "| "
    counts = "|  "

    sentenceDeclensionCounts = [0, 0, 0, 0, 0, 0, 0]
    caseAbbreviations = ['nom', 'accA', 'accI', 'prep', 'gen', 'dat', 'inst']

    await db.collection("SentencesAdjectives")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          var sentence = doc.data();
          sentenceDeclensionCounts[caseAbbreviations.indexOf(sentence.caseRequired)]++

        });
      })
      .catch((error) => {
          console.log("Error getting documents: ", error);
      });


    for (i = 0; i < sentenceDeclensionCounts.length; i++) {
      if (caseAbbreviations[i].length == 3) {
        names += caseAbbreviations[i] + " | "
        counts += sentenceDeclensionCounts[i] + "  |  "
      } else {
        names += caseAbbreviations[i] + " | "
        counts += sentenceDeclensionCounts[i] + "   |  "
      }
    }

    console.log("+---------------------------------------------+");
    console.log("| SENTENCES (ADJECTIVES)                      |");
    console.log("+---------------------------------------------+");
    console.log(names);
    console.log(counts);
    console.log("+---------------------------------------------+");



    var stats = await db.collection('Statistics').doc('STATISTICS').get();
    this.stats = stats.data();

    this.nounGenderQuantities = [this.stats.numberOfMasculineNouns, this.stats.numberOfFeminineNouns, this.stats.numberOfNeuterNouns]
    this.componentKey += 1;




  },
}
</script>










<!--  -->
