<template>
  <div class="polarChart">
    <apexcharts height="160"  type="pie" :options="chartOptions" :series="series"></apexcharts>
  </div>
</template>

<style scoped>
  .polarChart {
    align-items: center;
  }
</style>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'Chart',
  components: {
    apexcharts: VueApexCharts,
  },
  props: ['values'],
  data: function() {
    return {
      series: this.values,
      plotOptions: {
         polarArea: {
           dataLabels: {
              offset: 30
            }
         }
      },
      chartOptions: {
        labels: ['Masculine', 'Feminine', 'Neuter'],
        colors:['#a2d2ff', '#ffafcc', '#b5e48c'],
        legend: {
          show: false
        },
        yaxis: {
          max: 100,
          show: false
        },
        xaxis: {
              categories: ['Masc.', 'Fem.', 'Neut.']
            },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
              return Math.round(opts.w.globals.series[opts.seriesIndex]) + " " + opts.w.globals.labels[opts.seriesIndex]
          },
          background: {
            enabled: true,
            borderRadius:2,
          },
          style: {
            colors: ['#a2d2ff', '#ffafcc', '#b5e48c'],
          }
        },
        tooltip: {
          // enabled: false
        },
        chart: {
          type: 'pie',
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 1
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },
  methods: {
    addData(){
      this.updateChart();
    },
    updateChart() {
        this.series = [{
          data: this.values
        }]
      }
  },
}
</script>
